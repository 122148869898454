import React, { useState, useEffect } from 'react';
import { string, func } from 'prop-types';
import loadable from '@loadable/component';
import root from 'window-or-global';

import { Grid, Paper } from '@material-ui/core';
import useStyles from './styles';

const StartUp = loadable(() => import('components/landing-prices/start-up'));
const Bronze = loadable(() => import('components/landing-prices/bronze'));
const Silver = loadable(() => import('components/landing-prices/silver'));
const Gold = loadable(() => import('components/landing-prices/gold'));

const LandingPrices = ({ subscription = '', onExpand = () => {} }) => {
  const [stE, setStE] = useState(false);
  const [bE, setBE] = useState(false);
  const [sE, setSE] = useState(false);
  const [gE, setGE] = useState(false);
  const [selected, setSelected] = useState('');

  useEffect(() => {
    switch (subscription) {
      case 'start': {
        if (!stE) {
          setBE(false);
          setSE(false);
          setStE(false);
          setTimeout(() => {
            setSelected('start');
            onExpand();
            setStE(true);
          }, 500);
        }
        break;
      }
      case 'bronze': {
        if (!bE) {
          setSE(false);
          setGE(false);
          setStE(false);
          setTimeout(() => {
            setSelected('bronze');
            onExpand();
            setBE(true);
          }, 500);
        }
        break;
      }
      case 'silver': {
        if (!sE) {
          setBE(false);
          setGE(false);
          setStE(false);
          setTimeout(() => {
            setSelected('silver');
            onExpand();
            setSE(true);
          }, 500);
        }
        break;
      }
      case 'gold': {
        if (!gE) {
          setBE(false);
          setSE(false);
          setStE(false);
          setTimeout(() => {
            setSelected('gold');
            onExpand();
            setGE(true);
          }, 500);
        }
        break;
      }
      default:
    }

    // eslint-disable-next-line
  }, [subscription]);

  if (selected === 'start') {
    return (
      <>
      <Grid item md={12} sm={12} xs={12}>
        <StartUp onExpand={() => {
          if (stE) {
            setStE(false);
          } else {
            setBE(false);
            setSE(false);
            setStE(false);
            setTimeout(() => {
              setSelected('start');
              onExpand();
              setStE(true);
            }, 150);
          }
        }}
        expanded={stE}
        />
      </Grid>
      <Grid item sm={4} xs={12}>
        <Bronze onExpand={() => {
          setSE(false);
          setGE(false);
          setStE(false);
          setTimeout(() => {
            setSelected('bronze');
            onExpand();
            setTimeout(() => {
              setBE(true);
            }, 300);
          }, 150);
        }}
        expanded={bE}
        />
      </Grid>
      <Grid item sm={4} xs={12}>
        <Silver onExpand={() => {
          setBE(false);
          setGE(false);
          setStE(false);
          setTimeout(() => {
            setSelected('silver');
            onExpand();
            setTimeout(() => {
              setSE(true);
            }, 300);
          }, 150);
        }}
        expanded={sE}
        />
      </Grid>
      <Grid item sm={4} xs={12}>
        <Gold onExpand={() => {
          setBE(false);
          setSE(false);
          setStE(false);
          setTimeout(() => {
            setSelected('gold');
            onExpand();
            setTimeout(() => {
              setGE(true);
            }, 300);
          }, 150);
        }}
        expanded={gE}
        />
      </Grid>
      </>
    );
  }

  if (selected === 'bronze') {
    return (
      <>
      <Grid item md={12} sm={12} xs={12}>
        <Bronze onExpand={() => {
          if (bE) {
            setBE(false);
          } else {
            setSE(false);
            setGE(false);
            setStE(false);
            setTimeout(() => {
              setSelected('bronze');
              onExpand();
              setBE(true);
            }, 150);
          }
        }}
        expanded={bE}

        />
      </Grid>
      <Grid item sm={4} xs={12}>
        <Silver onExpand={() => {
          setBE(false);
          setGE(false);
          setStE(false);
          setTimeout(() => {
            setSelected('silver');
            onExpand();
            setTimeout(() => {
              setSE(true);
            }, 300);
          }, 150);
        }}
        expanded={sE}
        />
      </Grid>
      <Grid item sm={4} xs={12}>
        <Gold onExpand={() => {
          setBE(false);
          setSE(false);
          setStE(false);
          setTimeout(() => {
            setSelected('gold');
            onExpand();
            setTimeout(() => {
              setGE(true);
            }, 300);
          }, 150);
        }}
        expanded={gE}
        />
      </Grid>
      <Grid item sm={4} xs={12}>
        <StartUp onExpand={() => {
          setBE(false);
          setSE(false);
          setGE(false);
          setTimeout(() => {
            setSelected('start');
            onExpand();
            setTimeout(() => {
              setStE(true);
            }, 300);
          }, 150);
        }}
        expanded={stE}
        />
      </Grid>
      </>
    );
  }

  if (selected === 'silver') {
    return (
      <>
      <Grid item md={12} sm={12} xs={12}>
      <Silver onExpand={() => {
        if (sE) {
          setSE(false);
        } else {
          setBE(false);
          setGE(false);
          setStE(false);
          setTimeout(() => {
            setSelected('silver');
            onExpand();
            setSE(true);
          }, 150);
        }
      }}
      expanded={sE}
      />
      </Grid>
      <Grid item sm={4} xs={12}>
      <Gold onExpand={() => {
        setBE(false);
        setSE(false);
        setStE(false);
        setTimeout(() => {
          setSelected('gold');
          onExpand();
          setTimeout(() => {
            setGE(true);
          }, 300);
        }, 150);
      }}
      expanded={gE}
      />
      </Grid>
      <Grid item sm={4} xs={12}>
        <StartUp onExpand={() => {
          setBE(false);
          setSE(false);
          setGE(false);
          setTimeout(() => {
            setSelected('start');
            onExpand();
            setTimeout(() => {
              setStE(true);
            }, 300);
          }, 150);
        }}
        expanded={stE}
        />
      </Grid>
      <Grid item sm={4} xs={12}>
      <Bronze onExpand={() => {
        setSE(false);
        setGE(false);
        setStE(false);
        setTimeout(() => {
          setSelected('bronze');
          onExpand();
          setTimeout(() => {
            setBE(true);
          }, 300);
        }, 150);
      }}
      expanded={bE}
      />
      </Grid>
      </>
    );
  }

  if (selected === 'gold') {
    return (
      <>
      <Grid item md={12} sm={12} xs={12}>
        <Gold onExpand={() => {
          if (gE) {
            setGE(false);
          } else {
            setBE(false);
            setSE(false);
            setStE(false);
            setTimeout(() => {
              setSelected('gold');
              onExpand();
              setGE(true);
            }, 150);
          }
        }}
        expanded={gE}
        />
      </Grid>
      <Grid item sm={4} xs={12}>
        <StartUp onExpand={() => {
          setBE(false);
          setSE(false);
          setGE(false);
          setTimeout(() => {
            setSelected('start');
            onExpand();
            setTimeout(() => {
              setStE(true);
            }, 300);
          }, 150);
        }}
        expanded={stE}
        />
      </Grid>
      <Grid item sm={4} xs={12}>
        <Bronze onExpand={() => {
          setSE(false);
          setGE(false);
          setStE(false);
          setTimeout(() => {
            setSelected('bronze');
            onExpand();
            setTimeout(() => {
              setBE(true);
            }, 300);
          }, 150);
        }}
        expanded={bE}
        />
      </Grid>
      <Grid item sm={4} xs={12}>
        <Silver onExpand={() => {
          setBE(false);
          setGE(false);
          setStE(false);
          setTimeout(() => {
            setSelected('silver');
            onExpand();
            setTimeout(() => {
              setSE(true);
            }, 300);
          }, 150);
        }}
        expanded={sE}
        />
      </Grid>
      </>
    );
  }

  return (
      <>
      <Grid item lg={3} md={6} xs={12}>
        <StartUp onExpand={() => {
          setStE(true);
          setBE(false);
          setSE(false);
          setGE(false);
          setTimeout(() => {
            setSelected('start');
            onExpand();
          }, 150);
        }}
        expanded={stE}
        />
      </Grid>
      <Grid item lg={3} md={6} xs={12}>
        <Bronze onExpand={() => {
          setBE(true);
          setSE(false);
          setGE(false);
          setStE(false);
          setTimeout(() => {
            setSelected('bronze');
            onExpand();
          }, 150);
        }}
        expanded={bE}
        />
      </Grid>
      <Grid item lg={3} md={6} xs={12}>
        <Silver onExpand={() => {
          setSE(true);
          setBE(false);
          setGE(false);
          setStE(false);
          setTimeout(() => {
            setSelected('silver');
            onExpand();
          }, 150);
        }}
        expanded={sE}
        />
      </Grid>
      <Grid item lg={3} md={6} xs={12}>
        <Gold onExpand={() => {
          setGE(true);
          setBE(false);
          setSE(false);
          setStE(false);
          setTimeout(() => {
            setSelected('gold');
            onExpand();
          }, 150);
        }}
        expanded={gE}
        />
      </Grid>
    </>
  );
};

LandingPrices.propTypes = {
  subscription: string,
  focus: string,
  onExpand: func,
};

const WrappedLandingPrices = ({ subscription = '' }) => {
  const styles = useStyles();

  return (
      <Grid spacing={1}
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
          className={styles.wrapper}
          id="landing-prices-scroll-anchor"
       >
        <Grid item xs={12} md={10} lg={10}>
        <Paper className={styles.paper} elevation={4}>
          <Grid spacing={1}
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              className={styles.wrapper}
           >
            <LandingPrices subscription={subscription} onExpand={() => {
              const elmnt = root.document.getElementById('landing-prices-scroll-anchor');
              elmnt.scrollIntoView({ behavior: 'smooth', inline: 'start' });
            }}/>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

WrappedLandingPrices.propTypes = { subscription: string };

export default WrappedLandingPrices;
