import { makeStyles } from '@material-ui/core/styles';
import theme, { isDark } from 'theme';
import buttonStyles from 'apputil/button-styles';

export default makeStyles({
  ...buttonStyles,
  paper: theme.mixins.gutters({
    paddingTop: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(0.25),
  }),
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  star: {
    fontSize: theme.typography.fontSize * 2,
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: !isDark()
      ? theme.palette.primary.light : theme.palette.primary.dark,
    color: isDark() ? theme.palette.common.white : theme.palette.common.black,
    margin: theme.spacing(0),
  },
  startAvatar: {
    color: theme.palette.common.white,
    fontSize: theme.typography.fontSize * 2,
  },
  bronzeAvatar: {
    color: '#cd7f32',
    fontSize: theme.typography.fontSize * 2,
  },
  silverAvatar: {
    color: '#bec2cb',
    fontSize: theme.typography.fontSize * 2,
  },
  goldAvatar: {
    color: '#D4AF37',
    fontSize: theme.typography.fontSize * 2,
  },
  nomargin: {
    margin: theme.spacing(0),
    padding: theme.spacing(0),
  },
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    margin: 0,
    padding: 0,
  },
  icon: {
    textAlign: 'center',
    margin: theme.spacing(0),
    padding: theme.spacing(0),
    fontSize: theme.typography.fontSize * 2,
    color: theme.palette.secondary.light,
  },
  // fix
  activeCard: {
    boxShadow: `1px 1px 1px ${
      isDark()
        ? theme.palette.common.white
        : theme.palette.common.black
    }, 1px 1px 1px ${
      isDark()
        ? theme.palette.common.white
        : theme.palette.common.black
    } inset`,
  },
  activeButton: {
    boxShadow: `1px 1px 1px ${
      isDark()
        ? theme.palette.common.white
        : theme.palette.common.black
    }, 1px 1px 1px ${
      isDark()
        ? theme.palette.common.white
        : theme.palette.common.black
    } inset`,
  },
  center: {
    maxWidth: '100%',
  },
  card: {
    boxShadow: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    cursor: 'pointer',
  },
  noMarginBottom: {
    paddingBottom: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
  dividerMargin: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(0),
  },
  margin2: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  marginLeft2: {
    marginLeft: theme.spacing(3),
  },
  sideMargin2: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  rightMargin2: {
    marginRight: theme.spacing(2),
  },
  leftMargin2: {
    marginLeft: theme.spacing(2),
  },
  wrapper: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(0),
  },
  cardContainer: {
    color: theme.palette.grey[600],
  },
  pointer: {
    cursor: 'pointer',
  },
  infoIcon: {
    color: theme.palette.secondary.light,
  },
});
